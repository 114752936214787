<template>
  <a-card :bordered="false">
    <div class="table-operator">
      <a-popover v-model="dateVisible" title="选择日期" trigger="click">
        <a slot="content">
          <p><a-range-picker @change="onWeekDateChange" style="width: 200px" /></p>
          <p style="text-align: right"><a-button type="primary" @click="handleDateTimeStaticsExport">确定</a-button></p>
        </a>
        <a-button type="primary">导出周报统计表</a-button>
      </a-popover>
      <a-popover v-model="popVisible" title="选择月份" trigger="click">
        <a slot="content">
          <p><a-month-picker v-model="exportDate" style="width: 200px" format="YYYY/MM" placeholder="请选择月份" /></p>
          <p style="text-align: right"><a-button type="primary" @click="handleStaticsExport">确定</a-button></p>
        </a>
        <a-button type="primary">导出月度统计表</a-button>
      </a-popover>
      <a-popover v-model="yearVisible" title="选择年份" trigger="click">
        <a slot="content">
          <p>
            <a-date-picker
              :value="exportYear"
              mode="year"
              style="width: 200px"
              format="YYYY"
              :open="yearChoose"
              placeholder="请选择年份"
              @panelChange="chooseYear"
              @openChange="handleYearOpen"
            />
          </p>
          <p style="text-align: right"><a-button type="primary" @click="handleYearStaticsExport">确定</a-button></p>
        </a>
        <a-button type="primary">导出年度统计表</a-button>
      </a-popover>
      <a-popover v-model="dateVisible2" title="选择日期" trigger="click">
        <a slot="content">
          <p><a-range-picker @change="onWeekDateChange2" style="width: 200px" /></p>
          <p style="text-align: right"><a-button type="primary" @click="exportCustomerProfitSheet">确定</a-button></p>
        </a>
        <a-button type="primary">客户收益分析表</a-button>
      </a-popover>
      <a-popover v-model="dateVisible3" title="选择日期" trigger="click">
        <a slot="content">
          <p><a-range-picker @change="onWeekDateChange2" style="width: 200px" /></p>
          <p style="text-align: right"><a-button type="primary" @click="exportInvoiceCustomerProfitSheet">确定</a-button></p>
        </a>
        <a-button type="primary">客户开票收益分析表</a-button>
      </a-popover>
    </div>
    <a-tabs v-model="key" @change="changeTabs">
      <a-tab-pane tab="入库统计表" key="1" forceRender>
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <!-- <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="日期">
                  <a-range-picker @change="onDateChange('queryParam1', ...arguments)" v-model="param1DefaultDate"/>
                </a-form-item>
              </a-col> -->
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务编号">
                  <a-input v-model="queryParam1['%serial_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="委托客户">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    placeholder="请选择客户名称"
                    style="width: 100%"
                    :value="customerInfo1"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="getDepartmentCustomer"
                    @change="changeCustomerInfo"
                  >
                    <template v-if="fetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="进库核注清单号">
                  <a-input v-model="queryParam1['%check_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="商品名称">
                  <a-input v-model="queryParam1['%good_name']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="录单日期">
                  <a-range-picker @change="onDateChange('queryParam1', ...arguments)"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="客服员">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择客服员"
                    style="width: 100%"
                    v-model="queryParam1['%customerId']">
                    <a-select-option v-for="op in customerInfos" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :xs="6">
                <span class="table-page-search-submitButtons">
                  <a-button type="query" icon="search" @click="$refs.importTable.refresh(true)">查询</a-button>
                  <a-button type="primary" @click="handleExport('export_import_list')">导出</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <s-table
          ref="importTable"
          size="middle"
          :alert="{
            show: true,
            msg: showTotal1
          }"
          :rowKey="function(record) { return getRandomCode(8) }"
          bordered
          :columns="columns1"
          :data="loadData1"
          :scroll="{ x: 1000 }">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
      </a-tab-pane>
      <a-tab-pane tab="清关统计表" key="2" forceRender>
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <!-- <a-col :sm="12">
                <a-form-item label="日期">
                  <a-range-picker @change="onDateChange('queryParam2', ...arguments)" v-model="param2DefaultDate"/>
                </a-form-item>
              </a-col> -->
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务编号">
                  <a-input v-model="queryParam2['%serial_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="委托客户">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    placeholder="请选择客户名称"
                    style="width: 100%"
                    :value="customerInfo2"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="getDepartmentCustomer"
                    @change="changeCustomerInfo"
                  >
                    <template v-if="fetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="进库核注清单号">
                  <a-input v-model="queryParam2['check_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="商品名称">
                  <a-input v-model="queryParam2['%good_name']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="录单日期">
                  <a-range-picker @change="onDateChange('queryParam2', ...arguments)"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="客服员">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择客服员"
                    style="width: 100%"
                    v-model="queryParam2['%customer']">
                    <a-select-option v-for="op in customerInfos" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :xs="6">
                <span class="table-page-search-submitButtons">
                  <a-button type="query" icon="search" @click="$refs.clearTable.refresh(true)">查询</a-button>
                  <a-button type="primary" @click="handleExport('export_clear_list')">导出</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <s-table
          ref="clearTable"
          size="middle"
          :rowKey="function(record) { return getRandomCode(8) }"
          bordered
          :alert="{
            show: true,
            msg: showTotal2
          }"
          :columns="columns2"
          :data="loadData2"
          :scroll="{ x: 1000 }">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
      </a-tab-pane>
      <a-tab-pane tab="出库统计表" key="3" forceRender>
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <!-- <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="日期">
                  <a-range-picker @change="onDateChange('queryParam3', ...arguments)" v-model="param3DefaultDate"/>
                </a-form-item>
              </a-col> -->
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务编号">
                  <a-input v-model="queryParam3['%serial_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="委托客户">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    placeholder="请选择客户名称"
                    style="width: 100%"
                    :value="customerInfo3"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="getDepartmentCustomer"
                    @change="changeCustomerInfo"
                  >
                    <template v-if="fetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="进库核注清单号">
                  <a-input v-model="queryParam3['check_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="商品名称">
                  <a-input v-model="queryParam3['%good_name']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="录单日期">
                  <a-range-picker @change="onDateChange('queryParam3', ...arguments)"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="客服员">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择客服员"
                    style="width: 100%"
                    v-model="queryParam3['%customer']">
                    <a-select-option v-for="op in customerInfos" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :xs="6">
                <span class="table-page-search-submitButtons">
                  <a-button type="query" icon="search" @click="$refs.exportTable.refresh(true)">查询</a-button>
                  <a-button type="primary" @click="handleExport('export_list')">导出</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <s-table
          size="middle"
          :rowKey="function(record) { return getRandomCode(8) }"
          bordered
          ref="exportTable"
          :alert="{
            show: true,
            msg: showTotal3
          }"
          :columns="columns3"
          :data="loadData3"
          :scroll="{ x: 800 }">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
      </a-tab-pane>
      <a-tab-pane tab="库存明细" key="4" forceRender>
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务编号">
                  <a-input v-model="queryParam4['serial_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="委托客户">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    placeholder="请选择客户名称"
                    style="width: 100%"
                    :value="customerInfo4"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="getDepartmentCustomer"
                    @change="changeCustomerInfo"
                  >
                    <template v-if="fetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="进库核注清单号">
                  <a-input v-model="queryParam4['check_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="商品名称">
                  <a-input v-model="queryParam4['good_name']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="录单日期">
                  <a-range-picker @change="onDateChange4"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="客服员">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择客服员"
                    style="width: 100%"
                    v-model="queryParam4['customerId']">
                    <a-select-option v-for="op in customerInfos" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="是否有库存">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择是否有库存"
                    style="width: 100%"
                    v-model="queryParam4['whether_surplus']">
                    <a-select-option v-for="op in whetherSurplusData" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :xs="6">
                <span class="table-page-search-submitButtons">
                  <a-button type="query" icon="search" @click="$refs.storageTable.refresh(true)">查询</a-button>
                  <a-button type="primary" @click="handleExport2">导出</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <s-table
          size="middle"
          ref="storageTable"
          :rowKey="function(record) { return getRandomCode(8) }"
          bordered
          :columns="columns4"
          :data="loadData4"
          :scroll="{ x: 800 }">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import {
  getCumsStaticsPage,
  exportCumsReport,
  getCumsMetaOption,
  exportCumsFinance,
  exportCustomerProfitSheet,
  exportInvoiceCustomerProfitSheet,
  listStorage,
  exportListStorage
} from '@/api/cums'
import { downloadExcel, getCustomer } from '@/api/common'
import { getRandomCode, filterOption } from '@/utils/util'
import moment from 'moment'
import debounce from 'lodash/debounce';
export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      key: '1',
      customerInfos: [],
      whetherSurplusData: [
        { value: 2, name: '全部' },
        { value: 1, name: '是' },
        { value: 0, name: '否' }
      ],
      queryParam1: {},
      queryParam2: {},
      queryParam3: {},
      queryParam4: {
        whether_surplus: 2
      },
      customerInfo1: undefined,
      customerInfo2: undefined,
      customerInfo3: undefined,
      customerInfo4: undefined,
      fetching: false,
      showTotal1: '',
      showTotal2: '',
      showTotal3: '',
      param1DefaultDate: [moment().startOf('month'), moment().endOf('month')],
      param2DefaultDate: [moment().startOf('month'), moment().endOf('month')],
      param3DefaultDate: [moment().startOf('month'), moment().endOf('month')],
      param4DefaultDate: [moment().startOf('month'), moment().endOf('month')],
      inspectTypeMap: {},
      sourceOps: [],
      customerOps: [],
      popVisible: false,
      yearVisible: false,
      exportDate: null,
      yearChoose: false,
      exportYear: null,
      dateVisible: false,
      dateVisible2: false,
      dateVisible3: false,
      exportDateTime: null,
      export_start_date: null,
      export_end_date: null,
      columns1: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 50
        },
        {
          title: '业务编号',
          dataIndex: 'serial_num',
          width: 150
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name',
          width: 150
        },
        {
          title: '客服员',
          dataIndex: 'customer_name',
          width: 120
        },
        {
          title: '进库核注清单号',
          dataIndex: 'check_num',
          width: 130
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 150
        },
        {
          title: '箱型箱量',
          dataIndex: 'box_message',
          width: 100
        },
        {
          title: '规格型号',
          dataIndex: 'good_spec',
          width: 100
        },
        {
          title: '灌装日期',
          dataIndex: 'filling_date',
          width: 120,
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '商品料号',
          dataIndex: 'good_material',
          width: 100
        },
        {
          title: '货物数量',
          dataIndex: 'num',
          width: 100
        },
        {
          title: '货物单位',
          dataIndex: 'unit',
          width: 80
        },
        {
          title: '申报数量',
          dataIndex: 'upload_num',
          width: 100
        },
        {
          title: '申报计量单位',
          dataIndex: 'upload_unit',
          width: 100
        },
        {
          title: '法定数量',
          dataIndex: 'legal_num',
          width: 100
        },
        {
          title: '法定计量单位',
          dataIndex: 'legal_unit',
          width: 100
        },
        {
          title: '入库日期',
          dataIndex: 'created_at',
          width: 120,
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '录单人',
          dataIndex: 'creator',
          width: 100
        }
      ],
      loadData1: parameter => {
        const params = { ...this.queryParam1 }
        // params.created_at = params.created_at ? moment(params.created_at).startOf('day').valueOf() : moment().startOf('day').valueOf()
        return getCumsStaticsPage('list_import', Object.assign(parameter, params))
          .then(res => {
            this.showTotal1 = '箱型合计：' + res.total_row
            return res
          })
      },
      columns2: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 50
        },
        {
          title: '业务编号',
          dataIndex: 'serial_num',
          width: 150
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name',
          width: 150
        },
        {
          title: '客服员',
          dataIndex: 'customer_name',
          width: 120
        },
        {
          title: '进库核注清单号',
          dataIndex: 'check_num',
          width: 130
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 150
        },
        {
          title: '箱型箱量',
          dataIndex: 'box_message',
          width: 100
        },
        {
          title: '规格型号',
          dataIndex: 'good_spec',
          width: 100
        },
        {
          title: '灌装日期',
          dataIndex: 'filling_date',
          width: 120,
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '商品料号',
          dataIndex: 'good_material',
          width: 100
        },
        {
          title: '货物数量',
          dataIndex: 'num',
          width: 100
        },
        {
          title: '货物单位',
          dataIndex: 'unit',
          width: 80
        },
        {
          title: '申报数量',
          dataIndex: 'upload_num',
          width: 100
        },
        {
          title: '申报计量单位',
          dataIndex: 'upload_unit',
          width: 110
        },
        {
          title: '法定数量',
          dataIndex: 'legal_num',
          width: 100
        },
        {
          title: '法定计量单位',
          dataIndex: 'legal_unit',
          width: 110
        },
        {
          title: '入库日期',
          dataIndex: 'created_at',
          width: 100,
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '录单人',
          dataIndex: 'creator',
          width: 100
        }
      ],
      loadData2: parameter => {
        const params = { ...this.queryParam2 }
        // params.created_at = params.created_at ? moment(params.created_at).startOf('day').valueOf() : moment().startOf('day').valueOf()
        return getCumsStaticsPage('list_clear', Object.assign(parameter, params))
          .then(res => {
            this.showTotal2 = '箱型合计：' + res.total_row
            return res
          })
      },
      columns3: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 50
        },
        {
          title: '业务编号',
          dataIndex: 'serial_num',
          width: 150
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name',
          width: 150
        },
        {
          title: '客服员',
          dataIndex: 'customer_name',
          width: 120
        },
        {
          title: '出库核注清单号',
          dataIndex: 'clear_num',
          width: 130
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 150
        },
        {
          title: '箱型箱量',
          dataIndex: 'box_message',
          width: 100
        },
        {
          title: '规格型号',
          dataIndex: 'good_spec',
          width: 100
        },
        {
          title: '灌装日期',
          dataIndex: 'filling_date',
          width: 120,
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '商品料号',
          dataIndex: 'good_material',
          width: 100
        },
        {
          title: '货物数量',
          dataIndex: 'num',
          width: 100
        },
        {
          title: '货物单位',
          dataIndex: 'unit',
          width: 80
        },
        {
          title: '申报数量',
          dataIndex: 'upload_num',
          width: 100
        },
        {
          title: '申报计量单位',
          dataIndex: 'upload_unit',
          width: 110
        },
        {
          title: '法定数量',
          dataIndex: 'legal_num',
          width: 100
        },
        {
          title: '法定计量单位',
          dataIndex: 'legal_unit',
          width: 110
        },
        {
          title: '出库日期',
          dataIndex: 'created_at',
          width: 100,
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '录单人',
          dataIndex: 'creator',
          width: 100
        }
      ],
      loadData3: parameter => {
        const params = { ...this.queryParam3 }
        return getCumsStaticsPage('list_export', Object.assign(parameter, params))
          .then(res => {
            this.showTotal3 = '箱型合计：' + res.total_row
            return res
          })
      },
      columns4: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 50
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name',
          width: 150
        },
        {
          title: '客服员',
          dataIndex: 'customer_name',
          width: 120
        },
        {
          title: '进库核注清单号',
          dataIndex: 'check_num',
          width: 130
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 150
        },
        {
          title: '规格型号',
          dataIndex: 'good_spec',
          width: 100
        },
        {
          title: '商品料号',
          dataIndex: 'good_material',
          width: 100
        },
        {
          title: '货物数量',
          dataIndex: 'num',
          width: 100
        },
        {
          title: '货物单位',
          dataIndex: 'unit',
          width: 80
        },
        {
          title: '申报数量',
          dataIndex: 'upload_num',
          width: 100
        },
        {
          title: '申报计量单位',
          dataIndex: 'upload_unit',
          width: 110
        },
        {
          title: '法定数量',
          dataIndex: 'legal_num',
          width: 100
        },
        {
          title: '法定计量单位',
          dataIndex: 'legal_unit',
          width: 110
        },
        {
          title: '入库时间',
          dataIndex: 'store_date',
          width: 110
        },
        {
          title: '已清关货物单位数量',
          dataIndex: 'total_clear',
          width: 100
        },
        {
          title: '总体积',
          dataIndex: 'total_volume',
          width: 100
        },
        {
          title: '总净重',
          dataIndex: 'weight_total',
          width: 100
        },
        {
          title: '总毛重',
          dataIndex: 'gloss_weight_total',
          width: 100
        },
        {
          title: '未清关货物单位数量',
          dataIndex: 'un_clear',
          width: 100
        },
        {
          title: '已清关申报计量单位数量',
          dataIndex: 'total_clear_upload',
          width: 120
        },
        {
          title: '出库核注清单号',
          dataIndex: 'ex_check_num',
          width: 120
        },
        {
          title: '出库日期',
          dataIndex: 'export_date',
          width: 100,
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '客户出库单号',
          dataIndex: 'export_num',
          width: 120
        },
        {
          title: '出库货物单位数量',
          dataIndex: 'total_export',
          width: 100
        },
        {
          title: '出库申报计量单位数量',
          dataIndex: 'total_export_legal',
          width: 100
        },
        {
          title: '库存货物单位数量',
          dataIndex: 'total_storage',
          width: 100
        },
        {
          title: '库存申报计量单位数量',
          dataIndex: 'total_storage_legal',
          width: 100
        }
      ],
      loadData4: parameter => {
        const params = { ...this.queryParam4 }
        return listStorage(Object.assign(parameter, params))
          .then(res => {
            return res
          })
      }
    }
  },
  created() {
    getCumsMetaOption('cums_meta_info', { type: 7 }).then(v => {
      this.customerInfos = v;
    });
  },
  methods: {
    moment,
    filterOption,
    getRandomCode,
    chooseYear(date) {
      this.exportYear = date
      this.yearChoose = false
    },
    handleYearOpen(status) {
      if (status) {
        this.yearChoose = true
      } else {
        this.yearChoose = false
      }
    },
    handleDateTimeStaticsExport() {
      if (this.export_start_date && this.export_end_date) {
        this.handleDateStaticsExport('export_datetime', {
          firstDateTime: this.export_start_date,
          endDateTime: this.export_end_date
        })
        this.dateVisible = false
      } else {
        this.$message.warning('请选择导出日期')
      }
    },
    handleStaticsExport() {
      if (this.exportDate) {
        const year = this.exportDate.year()
        const month = this.exportDate.month()
        this.handleDateStaticsExport('export_data', {
          year: year,
          month: month + 1
        })
        this.popVisible = false
      } else {
        this.$message.warning('请选择导出月份')
      }
    },
    handleYearStaticsExport() {
      if (this.exportYear) {
        const year = this.exportYear.year()
        this.handleDateStaticsExport('export_year', {
          year: year
        })
        this.yearVisible = false
      } else {
        this.$message.warning('请选择导出年份')
      }
    },
    onWeekDateChange(date, dateString) {
      this.export_start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.export_end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
    },
    onWeekDateChange2(date, dateString) {
      this.queryParam1['start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.queryParam1['end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
    },
    handleDateStaticsExport(type, queryParam) {
      exportCumsFinance(type, queryParam || this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    changeTabs(key) {
      this.key = key
    },
    onDateChange(params, date, dateString) {
      const moments = []
      if (date[0]) {
        moments.push(date[0].startOf('day').valueOf())
        moments.push(date[1].endOf('day').valueOf())
      }
      this[params]['@record_time'] = moments // filling_date
    },
    onDateChange4(date, dateStr) {
      if (dateStr[0]) {
        this.queryParam4['start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam4['end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam4['start_date'] = null
        this.queryParam4['end_date'] = null
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 7,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      switch (this.key) {
        case '1':
          this.customerInfo1 = value
          this.queryParam1['auth_agency'] = value.key
          break;
        case '2':
          this.customerInfo2 = value
          this.queryParam2['auth_agency'] = value.key
          break;
        case '3':
          this.customerInfo3 = value
          this.queryParam3['auth_agency'] = value.key
          break;
        case '4':
          this.customerInfo4 = value
          this.queryParam4['auth_agency'] = value.key
          break;
      }
      this.customerOps = []
      this.fetching = false
    },
    handleExport(type) {
      let params = {}
      switch (type) {
        case 'export_import_list':
          params = { ...this.queryParam1 }
          break
        case 'export_clear_list':
          params = { ...this.queryParam2 }
          break
        case 'export_list':
          params = { ...this.queryParam3 }
          break
        case 'export_storage_list':
          params = { ...this.queryParam4 }
          break
      }
      exportCumsReport(type, params).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    handleExport2() {
      exportListStorage(this.queryParam4).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    // 导出客户收益分析表
    exportCustomerProfitSheet() {
      if (this.queryParam1['start_date'] && this.queryParam1['end_date']) {
        exportCustomerProfitSheet({
          start_date: this.queryParam1['start_date'],
          end_date: this.queryParam1['end_date']
        }).then(res => {
          if (res !== null) {
            const fileInfo = {
              fileName: res
            }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        });
        this.dateVisible2 = false;
      } else {
        this.$message.warning('请选择导出日期');
      }
    },
    // 导出客户开票收益分析表
    exportInvoiceCustomerProfitSheet() {
      if (this.queryParam1['start_date'] && this.queryParam1['end_date']) {
        exportInvoiceCustomerProfitSheet({
          start_date: this.queryParam1['start_date'],
          end_date: this.queryParam1['end_date']
        }).then(res => {
          if (res !== null) {
            const fileInfo = {
              fileName: res
            }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        });
        this.dateVisible3 = false;
      } else {
        this.$message.warning('请选择导出日期');
      }
    }
  }
}
</script>
